import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'

const RootRouteDispatcher = () => {
    const auth = useSelector(s => s.auth)

    if (!auth.isLoggedIn || auth.role === "GUEST") {
        return <Redirect to={"/authentication/login"} />
    }

    if (auth.role === "ADMIN") {
        return <Redirect to={"/dashboard"} />
    }

    if (auth.role === "OPERAIO") {
        return <Redirect to={"/operaio"} />
    }

    if (auth.role === "RESPONSABILE") {
        return <Redirect to={"/responsabile"} />
    }

}

export default RootRouteDispatcher
