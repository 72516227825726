import React from "react";

import { Divider, Row, Col } from "antd";

export default function MenuFooter(props) {
  return (
    props.collapsed === false && (
      <Row
        className="hp-sidebar-footer hp-bg-color-dark-90"
        align="middle"
        justify="space-between"
      >
        <Divider className="hp-border-color-black-40 hp-border-color-dark-70 hp-mt-0" />

        <Col>
          <h2 className="hp-font-weight-400">Service <br /> Manager.</h2>
        </Col>
      </Row>
    )
  );
};