import React from 'react'

import Slider from "react-slick";
import { Avatar, Button, Col, Row } from 'antd'
import { Message, Star, Setting, Category, Graph } from "react-iconly";


export default function LandingFeatures() {


    return (
        <section className="hp-landing-features hp-py-96 hp-bg-color-info-4">
            <Row align="middle" justify="center">
                <Col lg={14} md={16} span={24} className="hp-px-16 hp-text-center">
                    <h2 className="h2 hp-mb-32">
                        Desideri scoprire come Service Manager può rivoluzionare la gestione dei tuoi servizi?
                    </h2>

                </Col>

                <Col span={24} className="hp-px-16 hp-text-center ">
                    <Button type="primary">Richiedi una demo</Button>
                </Col>
            </Row>
        </section>
    )
}