import { Health, Setting, Grid5, Calendar, NotificationStatus } from 'iconsax-react';

import IntlMessages from "../../layout/components/lang/IntlMessages";

const main = [
    {
        header: "Operaio"
    },
    {
        id: "commesse",
        title: "Commesse",
        icon: <NotificationStatus size={18} />,
        navLink: "/operaio",
    },
];

export default main