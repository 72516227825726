import { Health, Setting, Grid5, TagUser, Building, NotificationStatus } from 'iconsax-react';

import IntlMessages from "../../layout/components/lang/IntlMessages";

const main = [
    {
        header: "Anagrafiche"
    },
    {
        id: "users",
        title: "Utenti",
        icon: <TagUser size={18} />,
        navLink: "/utenti",
    },
    {
        id: "sedi",
        title: "Sedi",
        icon: <Building size={18} />,
        navLink: "/sedi",
    },
    {
        id: "commesse",
        title: "Commesse",
        icon: <NotificationStatus size={18} />,
        navLink: "/commesse",
    },
    {
        id: "servizi",
        title: "Servizi",
        icon: <Setting size={18} />,
        navLink: "/servizi",
    },
];

export default main