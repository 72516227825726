import { Col } from "antd";

import image from "../../../assets/images/memoji/newspaper.svg";

export default function HeaderText() {
  return (
    <Col
      xl={16}
      lg={14}
      className="hp-header-left-text hp-d-flex-center"
    >
     <span><span className="hp-text-color-primary-1">Service Manager</span>, il tuo gestore di servizi.</span> 
    </Col>
  );
};