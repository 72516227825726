import gestione from "./gestione";
import anagrafiche from './anagrafiche';
import operaio from './operaio';
import { ROLES } from "../../data/roles";
// import apps from "./apps";
// import pages from "./pages";
// import userInterface from "./user-interface";

const navigation = {
    [ROLES[0]]: [...gestione, ...anagrafiche],
    [ROLES[2]]: [...operaio]
}


export default navigation