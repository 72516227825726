import * as actionTypes from './authTypes';

const INITIAL_STATE = {
    isLoggedIn: false,
    role: "GUEST",
    azienda: {
        logo: "",
        nome: ""
    }
}

const authReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.LOGIN:
            return {
                ...state,
                isLoggedIn: true,
                role: action.payload.role,
                azienda: action.payload.azienda
            };

        case actionTypes.LOGOUT:
            return INITIAL_STATE;

        default:
            return state;
    };
}

export default authReducer;