import { Health, Setting, Grid5, Calendar, Box } from 'iconsax-react';

import IntlMessages from "../../layout/components/lang/IntlMessages";

const main = [
    {
        header: "Gestione"
    },
    {
        id: "dashboard",
        title: "Dashboard",
        icon: <Health size={18} />,
        navLink: "/dashboard",
    },
    {
        id: "calendar",
        title: "Pianifica",
        icon: <Calendar size={18} />,
        navLink: "/pianifica",
    },
    {
        id: "oggettismarriti",
        title: "Oggetti smarriti",
        icon: <Box size={18} />,
        navLink: "/oggetti"
    }
];

export default main