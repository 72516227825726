import axios from "axios";

export const API_BASE_URL = "https://api.servicemanager.cloud/v1/";

const instance = axios.create({
    baseURL: API_BASE_URL,
});

instance.defaults.withCredentials = false;
instance.interceptors.request.use((config) => {
    if (localStorage.getItem("authkey")) {
        config.headers["Authorization"] = localStorage.getItem("authkey");
    }

    return config;
})

instance.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if(error?.response?.status === 401){
        return location.replace("/authentication/login");
    }
    return Promise.reject(error);
  })

export { instance };
