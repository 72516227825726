import * as authTypes from './authTypes';

export const login = (value) => {
    return {
        type: authTypes.LOGIN,
        payload: value
    };
}

export const logout = (value) => {
    localStorage.removeItem("authkey")
    return {
        type: authTypes.LOGOUT,
        payload: value
    };
}