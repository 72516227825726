import { lazy } from "react";

const adminRoutes = [
  // admin
  {
    path: "/pianifica",
    component: lazy(() => import("../../view/main/pianifica")),
    layout: "VerticalLayout",
    role: "ADMIN",
  },
  {
    path: "/dashboard",
    component: lazy(() => import("../../view/main/dashboard/admin")),
    layout: "VerticalLayout",
    role: "ADMIN",
  },
  {
    path: "/utenti",
    component: lazy(() => import("../../view/anagrafiche/utenti")),
    layout: "VerticalLayout",
    role: "ADMIN",
  }, {
    path: "/sedi",
    component: lazy(() => import("../../view/anagrafiche/sedi")),
    layout: "VerticalLayout",
    role: "ADMIN",
  },
  {
    path: "/commesse",
    component: lazy(() => import("../../view/anagrafiche/commesse")),
    layout: "VerticalLayout",
    role: "ADMIN",
    exact: true
  },
  {
    path: "/commesse/:id",
    component: lazy(() => import("../../view/anagrafiche/commesse/monitora")),
    layout: "VerticalLayout",
    role: "ADMIN",
    exact: true
  },
  {
    path: "/servizi",
    component: lazy(() => import("../../view/anagrafiche/servizi")),
    layout: "VerticalLayout",
    role: "ADMIN",
  },
  {
    path: "/oggetti",
    component: lazy(() => import("../../view/main/oggetti")),
    layout: "VerticalLayout",
    role: "ADMIN"
  },


]

const operaioRoutes = [
  {
    path: "/operaio",
    component: lazy(() => import('../../view/checkin/operaio')),
    layout: "VerticalLayout",
    role: "OPERAIO"
  },

  {
    path: "/lavora/:id",
    component: lazy(() => import("../../view/checkin/operaio/lavora")),
    layout: "VerticalLayout",
    role: "OPERAIO"
  },
]

const responsabileRoutes = [
  {
    path: "/responsabile",
    component: lazy(() => import('../../view/checkin/responsabile')),
    layout: "VerticalLayout",
    role: "RESPONSABILE"
  },

  {
    path: "/controlla/:id",
    component: lazy(() => import("../../view/checkin/responsabile/controlla")),
    layout: "VerticalLayout",
    role: "RESPONSABILE"
  },
]

const PagesRoutes = [


  ...adminRoutes,

  ...operaioRoutes,

  ...responsabileRoutes,

  {
    path: "/authentication/login",
    component: lazy(() => import('../../view/pages/authentication/login')),
    layout: "FullLayout",
    role: "GUEST"
  },

];

export default PagesRoutes;