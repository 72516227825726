import React from 'react'

import LandingHeader from './header';
import LandingHero from './hero';
import LandingCompanies from './companies';
import LandingFeatures from './features';
import LandingContent from './content-1';
import LandingPeople from './people';
import LandingPricing from './pricing';
import LandingFooter from './footer';

import RootRouteDispatcher from '../../../RootRouteDispatcher';

export default function Landing() {
    return (
        <>
            <RootRouteDispatcher />
            <div className="hp-landing hp-bg-black-0 hp-bg-dark-90">
                <LandingHeader />

                <LandingHero />

                <LandingFeatures />

                <LandingContent
                    titolo="Ottimizzazione operativa"
                    sottotitolo="Service Manager ti aiuta a ottimizzare tutte le operazioni legate alla gestione dei servizi. Riduci al minimo le inefficienze, ottimizza le risorse e massimizza la produttività complessiva."
                />

                <LandingContent
                    titolo="Gestione efficace delle risorse"
                    sottotitolo="Con Service Manager, puoi pianificare e allocare le risorse in modo efficiente. Assegna compiti e responsabilità, evita sovraccarichi di lavoro e massimizza l'utilizzo delle risorse disponibili."
                    reversed
                />

                <LandingContent
                    titolo="Flessibilita e adattabilita"
                    sottotitolo="Service Manager è progettato per adattarsi alle tue esigenze aziendali specifiche. Il software soddisfa i tuoi requisiti unici, migliorando l'efficienza operativa"
                />


                <LandingPeople />

                <LandingPricing />

                <LandingFooter />
            </div>
        </>
    )
}
